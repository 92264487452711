<template>
    <div class="popup">
        <Loading size="32" :isShow="loading" :fullscreen="true" />
        <div class="box" v-if="!loading">
            <p class="popTitle">{{ xrayName }} 변화 추이</p>
            <div class="cont">
                <GraphCompo :xrayType="xrayType" :xrayNo="xrayNo" @loading="loading = false" />
            </div>
            <div class="btnW popBtn">
                <button class="btn default" @click="$emit('closePopup')" v-ripple>{{ CONST_CLOSE }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        GraphCompo: () => import("@/components/graphCompo.vue"),
    },
    mixins: {},
    props: {
        xrayType:{
            default:'',
        },
        xrayNo:{
            default:'',
        },
        xrayName:{
            default:'',
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.cont{
    min-height: 300px;
}
</style>
